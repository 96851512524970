<template>
  <div :class="{'container' : true, 'vh-50' : screenWidth > 800}" id="our-process">
    <div class="our-process-section">
      <div class="row">
        <div class="col-12">
          <div data-aos="fade-right"
               data-aos-anchor="#example-anchor"
               data-aos-offset="600"
               data-aos-duration="2000">
            <p class="our-process">OUR PROCESS</p>
            <p class="our-process-sub">
              At our company, we specialize in crafting digital solutions tailored to businesses and organizations of
              all
              sizes. With a deep well of knowledge and extensive experience, <br> we bring your digital aspirations to life,
              whether it's through bespoke website designs or robust mobile applications. Our dedicated team
              collaborates
              closely with clients, <br> ensuring a thorough understanding of their unique requirements and establishing
              transparent communication channels to deliver exceptional outcomes.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="process-skills">
      <div class="row">
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="1000">
          <div class="circle">
            <img src="../assets/img/to-do-list.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Requirement</p>
        </div>
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="1200">
          <div class="circle">
            <img src="../assets/img/graphic-design.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Design</p>
        </div>
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="1400">
          <div class="circle">
            <img src="../assets/img/professional-development.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Development</p>
        </div>
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="1600">
          <div class="circle">
            <img src="../assets/img/professional-board.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Testing</p>
        </div>
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="1800">
          <div class="circle">
            <img src="../assets/img/rocket.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Deployment</p>
        </div>
        <div class="circle-container col d-flex flex-column justify-content-between" data-aos="fade-up"
             data-aos-duration="2000">
          <div class="circle">
            <img src="../assets/img/on-time.png" class="skill-svg" alt="">
          </div>
          <p class="circle-text">Delivery</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OurProcess',
  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    }
  }
}

</script>

<style scoped>

.our-process {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
}

.our-process-sub {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.process-skills {
  margin-top: 100px;
}

.circle-container {
  align-items: center;
  position: relative;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 -2px 40px 0 rgba(4, 121, 247, 0.13),
  0 -2px 10px 0 rgba(4, 121, 247, 0.14);
  display: flex;
  background-color: rgba(240, 247, 255, 1);
  justify-content: center;
  align-items: center;
}

.skill-svg {
  max-width: 80%;
  max-height: 80%;
  padding: 10px;
}

.circle-text {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 9px !important;
}

@media (max-width: 991px) {
  .circle {
    width: 80px;
    height: 80px;
  }

  .circle-text {
    font-size: 17px;
  }
}

@media (max-width: 820px) {
  .our-process-section {
    margin-top: 100px;
  }
}

@media (max-width: 660px) {
  .circle {
    width: 70px;
    height: 70px;
  }

  .our-process {
    font-size: 25px;
  }

  .our-process-sub {
    font-size: 20px;
  }

  .process-skills {
    margin-top: unset;
  }
}

</style>