<template>
  <div id="app">
    <NavBar></NavBar>
    <OurProcess></OurProcess>
    <OurPartners></OurPartners>
    <OurTechnologies></OurTechnologies>
    <AboutUs></AboutUs>
    <ContactUs></ContactUs>
    <FooterMenu></FooterMenu>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import OurProcess from "@/components/OurProcess.vue";
import OurPartners from "@/components/OurPartners.vue";
import OurTechnologies from "@/components/OurTechnologies.vue";
import AboutUs from "@/components/AboutUs.vue";
import ContactUs from "@/components/ContactUs.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: 'App',
  components: {
    FooterMenu,
    ContactUs,
    AboutUs,
    OurTechnologies,
    OurPartners,
    OurProcess,
    NavBar,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #232323 !important;
  width: 100%;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #212529;
}
</style>
