<template>
  <div>
    <div class="container contact-us" id="contact-us">
      <div class="row m-0 p-0">
        <div class="col-lg-6 col-md-12 contact-us-box">
          <div class="contact-us-text">
            <p>Contact us</p>
            <p class="contact-us-subtitle">Thank you for your time. We really appreciate your interest in our company.
              If you want to contact us
              please see contact information below</p>
          </div>
          <div class="contact-us-credits">
            <div>
              <div class="d-flex">
                <span><img src="../assets/img/phone.png" alt=""></span>
                <div class="pl-2">
                  <p>+374 41 13 07 93</p>
                </div>
              </div>
              <div class="d-flex email-section">
                <span><img src="../assets/img/mail.png" alt=""></span>
                <div class="pl-2">
                  <p>info@codeidea.am</p>
                </div>
              </div>
              <div class="d-flex location-section">
                <span><img src="../assets/img/location.png" alt=""></span>
                <div class="pl-2">
                  <p>Anastas Mikoyan 64/5, Yerevan, Armenia</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 form-section col-12">
          <div class="form-text">
            <p class="lets-discuss">Let’s discuss your project</p>
            <p class="form-subtitle">Let's explore and dive deeper into your project through meaningful discussions and
              valuable insights.</p>
          </div>
          <form @submit.prevent="submitForm">
            <div class="contact-us-form">
              <div class="form-block">
                <span>Name</span>
                <input class="form-input" type="text" v-model="formData.name">
              </div>
              <div class="form-block">
                <span>Email</span>
                <input class="form-input" type="email" v-model="formData.email">
              </div>
              <div class="form-block">
                <span>Subject</span>
                <input class="form-input" type="text" v-model="formData.subject">
              </div>
              <div class="form-block">
                <span>Write your message</span>
                <input class="form-input" v-model="formData.message">
              </div>
              <div class="text-center">
                <button type="submit" class="btn form-contact-us-button">Send message</button>
              </div>
            </div>
          </form>
        </div>
        <div class="container people-img" v-if="screenWidth < 900">
          <img src="../assets/img/peopleGroup.png" alt="" class="people">
        </div>
        <div class="container people-img" v-else>
          <div class="row">
            <div class="col-5" data-aos="fade-right" data-aos-duration="2000">
              <img src="../assets/img/womanGreeting.png" alt="" class="people">
            </div>
            <div class="col-7" data-aos="fade-left" data-aos-duration="2000">
              <img src="../assets/img/manWithNote.png" alt="" class="people">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactUs',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      screenWidth: window.innerWidth
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    },
    async submitForm() {
      try {
        const response = await fetch('https://codeidea.am/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: JSON.stringify(this.formData)
        });

        if (response.ok) {
          console.log('Email sent successfully');
          this.formData = {
            name: '',
            email: '',
            subject: '',
            message: ''
          };
        } else {
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  }
}

</script>

<style scoped>

.contact-us {
  margin-top: 135px;
}

.contact-us-box {
  background: rgba(4, 121, 247, 1);
  border-radius: 31px 0 0 31px;
  border: 4px solid rgba(4, 118, 241, 1);
}

.contact-us-text {
  padding: 104px 40px 0 40px;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: white;
}

.contact-us-credits {
  padding: 60px 40px;
  color: white;
}

.contact-us-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
  padding-top: 30px;
}

.email-section {
  padding-top: 38px;
}

.location-section {
  padding-top: 30px;
}

.form-section {
  border: 5px solid rgba(4, 118, 241, 1);
  border-radius: 0 31px 31px 0;
}

.form-text {
  padding: 41px 57px 0 85px;
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
}

.form-subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.contact-us-form {
  padding: 50px 88px 145px 88px;
}

.form-block {
  width: 100%;
  border-bottom: 1px solid rgba(35, 35, 35, 1);
  padding-bottom: 13px;
  margin-bottom: 27px;
}

.form-input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background: transparent;
  font-size: 18px;
  line-height: 1.2;
  padding: 0 5px;
}

.form-contact-us-button {
  border: 1px solid rgba(0, 0, 0, 1);
  color: black;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 14px 60px;
  border-radius: 30px;
}

.people-img {
  margin-top: 180px;
}

@media (max-width: 1400px) {
  .people {
    width: 100%;
    object-fit: contain;
  }

  .people-img {
    padding: unset;
  }
}

@media (max-width: 991px) {
  .form-section {
    border-radius: 31px;
    margin-top: 5px;
  }

  .contact-us-box {
    border-radius: 31px;
  }

  .contact-us-text {
    padding: 20px 40px 0 40px;
  }

  .contact-us-credits {
    padding: 15px 40px;
  }

  .contact-us-form {
    padding: 20px 88px 30px 88px;
  }

  .people-img {
    margin-top: 50px;
  }
}

@media (max-width: 450px) {
  .contact-us-form {
    padding: unset;
  }

  .text-center {
    padding-bottom: 10px;
  }

  .form-text {
    padding: unset;
  }

  .contact-us-text {
    padding: unset;
  }

  .contact-us-credits {
    padding: unset;
  }

}

</style>