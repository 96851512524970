<template>
  <div>
    <div class="container" id="technologies">
      <div class="technologies-container">
        <div class="row">
          <div :class="{'col-6' : screenWidth > 749}">
            <p class="technologies-we-use">TECHNOLOGIES WE USE</p>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;">
      <img src="../assets/img/lamp.png" alt="" v-if="screenWidth > 1440" data-aos="fade-right"
           data-aos-anchor="#example-anchor"
           data-aos-offset="600"
           data-aos-duration="2000"
           style="position: absolute;left: 0;top: 45%;">
      <div class="container p-0">
        <div class="technologies-sub-name col-6">
          <p class="technologies-we-use">Client-Side Programming</p>
        </div>
        <div class="col-10" data-aos="fade-left"
             data-aos-duration="1000" v-if="screenWidth > 600">
          <p class="technologies-subtitle">We prioritize responsive and interactive UI/UX design to ensure your app's
            success. Our team crafts designs tailored to your expectations, delighting customers and encouraging
            prolonged engagement. Our goal is to create simple, functional apps with stunning interfaces, offering
            users
            ample information and a seamless experience.</p>
        </div>
        <div class="col-10" v-else>
          <p class="technologies-subtitle">We prioritize responsive and interactive UI/UX design to ensure your app's
            success. Our team crafts designs tailored to your expectations, delighting customers and encouraging
            prolonged engagement. Our goal is to create simple, functional apps with stunning interfaces, offering
            users
            ample information and a seamless experience.</p>
        </div>
        <div :class="{'row': true, 'justify-content-center': screenWidth < 750 }">
          <template v-if="screenWidth < 750">
            <div class="row icons-block justify-content-center">
              <div class="circle-container">
                <div class="circle mt-3">
                  <img src="../assets/img/yellow-box.png" class="skill-svg yellow-box" alt="">
                  <img src="../assets/img/js.png" alt="" class="text-in-yellow-box">
                  <p class="circle-text">JavaScript</p>
                </div>
              </div>
              <div class="circle-container"
                   v-for="(icon, index) in iconsRowFirst" :key="'first_' + index">
                <div class="circle mt-3">
                  <img :src="icon.image" class="skill-svg" alt="">
                  <p class="circle-text">{{ technologiesRowFirst[index].text }}</p>
                </div>
              </div>
              <div class="circle-container"
                   v-for="(icon, index) in iconsRowSecond" :key="'second_' + index">
                <div class="circle mt-3">
                  <img :src="icon.image" class="skill-svg" alt="">
                  <p class="circle-text">{{ technologiesRowSecond[index].text }}</p>
                </div>
              </div>
            </div>
          </template>
          <div class="col-6 icons-block" v-else>
            <div :class="{'process-skills': true}">
              <div :class="{'d-flex': true, 'flex-wrap': screenWidth < 750 }">
                <div class="circle-container">
                  <div class="circle">
                    <img src="../assets/img/yellow-box.png" class="skill-svg yellow-box" alt="">
                    <img src="../assets/img/js.png" alt="" class="text-in-yellow-box">
                    <p class="circle-text">JavaScript</p>
                  </div>
                </div>
                <div class="circle-container"
                     v-for="(icon, index) in iconsRowFirst" :key="'first_' + index">
                  <div class="circle">
                    <img :src="icon.image" class="skill-svg" alt="">
                    <p class="circle-text">{{ technologiesRowFirst[index].text }}</p>
                  </div>
                </div>
              </div>
              <div :class="{'d-flex': true, 'flex-wrap': screenWidth < 750 }">
                <div class="circle-container mt-3"
                     v-for="(icon, index) in iconsRowSecond" :key="'second_' + index">
                  <div class="circle">
                    <img :src="icon.image" class="skill-svg" alt="">
                    <p class="circle-text">{{ technologiesRowSecond[index].text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 woman-section" v-if="screenWidth > 1200" data-aos="zoom-in-up" data-aos-duration="2000">
            <img src="../assets/img/woman.png" alt="" class="content-woman-image">
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;">
      <img src="../assets/img/lamp2.png" alt="" v-if="screenWidth > 1440"
           style="position: absolute; right:0; top: 0;">
      <div class="container p-0">
        <div class="server-side" data-aos="fade-right" data-aos-duration="2000">
          <div class="technologies-sub-name col-6">
            <p class="technologies-we-use">Server-Side Programming</p>
          </div>
          <div class="col-10">
            <p class="technologies-subtitle">We offer various server-side programming languages to develop the logic of
              websites and applications, tailored to your specific requirements. Our development team builds robust and
              dependable backends for both web and mobile applications. We prioritize collaborative efforts with our
              clients to determine the most suitable technology stack, ensuring the most effective and efficient
              solution
              for architecting the technology.</p>
          </div>
          <div :class="{'row icons-block' : true,  'justify-content-center' : screenWidth < 991}">
            <div class="mt-3 col"
                 v-for="(icon, index) in iconsServerSide" :key="'second_' + index">
              <div class="circle">
                <img :src="icon.image" class="skill-svg" alt="">
                <p class="circle-text">{{ technologiesServerSide[index].text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;">
      <div class="container p-0">
        <img src="../assets/img/line1.png" alt="" v-if="screenWidth > 1440"
             style="position: absolute; left:0; top: -30px;">
        <div class="database-management" data-aos="fade-right" data-aos-duration="2000">
          <div class="technologies-sub-name col-6 mt-5">
            <p class="technologies-we-use">Database Management</p>
          </div>
          <div class="col-10">
            <p class="technologies-subtitle">In data storage, one size doesn't fit all. Each application is unique,
              requiring careful consideration. We assess data volume and type, consult with clients, and recommend the
              best-fit solution. Our experienced team handles various Database Management Systems, BI solutions, NoSQL
              databases, and queueing systems.</p>
          </div>
          <div :class="{'row icons-block' : true,  'justify-content-center' : screenWidth < 991}">
            <div class="col mt-3"
                 v-for="(icon, index) in iconsDatabaseManagement" :key="'second_' + index">
              <div class="circle">
                <img :src="icon.image" class="skill-svg" alt="">
                <p class="circle-text">{{ technologiesDatabaseManagement[index].text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="../assets/img/lamp3.png" alt="" v-if="screenWidth > 1440"
           style="position: absolute; right:0; top: 0;">
      <div style="position: relative;">
        <div class="container p-0">
          <img src="../assets/img/line1.png" alt="" v-if="screenWidth > 1440"
               style="position: absolute; left:0; top: -30px;">
          <div class="cloud-network" data-aos="fade-right" data-aos-duration="2000">
            <div class="technologies-sub-name col-6 mt-5">
              <p class="technologies-we-use">Cloud Network</p>
            </div>
            <div class="col-10">
              <p class="technologies-subtitle">While beneficial, transitioning to cloud computing can pose challenges.
                However, it offers cost savings and enhanced flexibility. At CodeIdea, we understand the significance of
                expertise in various cloud services and deployment models. We tailor solutions to meet each client's
                specific needs. Our proficient DevOps engineering team manages all aspects of cloud infrastructure,
                ensuring
                high performance and reliability.</p>
            </div>
            <div :class="{'row icons-block' : true,  'justify-content-center' : screenWidth < 991}">
              <div class="col mt-3"
                   v-for="(icon, index) in iconsCloudNetwork" :key="'second_' + index">
                <div class="circle">
                  <img :src="icon.image" class="skill-svg" alt="">
                  <p class="circle-text">{{ technologiesCloudNetwork[index].text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurTechnologies',
  data() {
    return {
      iconsRowFirst: [
        {image: require("@/assets/img/react.png")},
        {image: require("@/assets/img/angular.png")},
        {image: require("@/assets/img/ionic.png")},
        {image: require("@/assets/img/vue-js.png")},
      ],
      technologiesRowFirst: [
        {text: 'React'},
        {text: 'Angular'},
        {text: 'IOnic'},
        {text: 'Vue'},
      ],
      iconsRowSecond: [
        {image: require("@/assets/img/nuxt-js.png")},
        {image: require("@/assets/img/html5.png")},
        {image: require("@/assets/img/css.png")},
        {image: require("@/assets/img/redux.png")},
      ],
      technologiesRowSecond: [
        {text: 'Nuxt.js'},
        {text: 'HTML 5'},
        {text: 'CSS 3'},
        {text: 'Redux'},
      ],
      iconsServerSide: [
        {image: require("@/assets/img/php.png")},
        {image: require("@/assets/img/laravel.png")},
        {image: require("@/assets/img/node-js.png")},
        {image: require("@/assets/img/java.png")},
        {image: require("@/assets/img/python.png")},
        {image: require("@/assets/img/django.png")},
        {image: require("@/assets/img/ios.png")},
        {image: require("@/assets/img/android.png")},
      ],
      technologiesServerSide: [
        {text: 'PHP'},
        {text: 'LARAVEL'},
        {text: 'NODE.JS'},
        {text: 'JAVA'},
        {text: 'PYTHON'},
        {text: 'DJANGO'},
        {text: 'iOS'},
        {text: 'ANDROID'},
      ],
      iconsDatabaseManagement: [
        {image: require("@/assets/img/mysql.png")},
        {image: require("@/assets/img/postgresql.png")},
        {image: require("@/assets/img/sqlite.png")},
        {image: require("@/assets/img/mongodb.png")},
        {image: require("@/assets/img/redis.png")},
        {image: require("@/assets/img/clickhouse.png")},
        {image: require("@/assets/img/elasticsearch.png")},
      ],
      technologiesDatabaseManagement: [
        {text: 'MySQL'},
        {text: 'PostgreSQL'},
        {text: 'SQLite'},
        {text: 'Mongo DB'},
        {text: 'Redis'},
        {text: 'ClickHouse'},
        {text: 'ElasticSearch'},
      ],
      iconsCloudNetwork: [
        {image: require("@/assets/img/aws.png")},
        {image: require("@/assets/img/google.png")},
        {image: require("@/assets/img/heroku.png")},
        {image: require("@/assets/img/firebase.png")},
        {image: require("@/assets/img/docker.png")},
        {image: require("@/assets/img/kubernetes.png")},
      ],
      technologiesCloudNetwork: [
        {text: 'AWS'},
        {text: 'Google'},
        {text: 'Heroku'},
        {text: 'FireBase'},
        {text: 'Docker'},
        {text: 'Kubernetes'},
      ],
      screenWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    }
  }
};
</script>

<style scoped>
.technologies-container {
  margin-top: 100px;
}

.technologies-we-use {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
}

.technologies-subtitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.circle-container {
  align-items: center;
  position: relative;
  margin-right: 18px;
}

.circle {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #FAFCFF;
  box-shadow: 0 4px 4px 0 rgba(4, 121, 247, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  cursor: pointer;
}

.circle:hover {
  box-shadow: 0 -2px 40px 0 rgba(4, 121, 247, 0.13),
  0 -2px 10px 0 rgba(4, 121, 247, 0.33);
}

.circle-text {
  margin-top: 5px;
  font-size: 14px;
}

.woman-section {
  margin-top: -85px;
}

.yellow-box {
  position: relative;
}

.text-in-yellow-box {
  position: absolute;
  padding-bottom: 23px;
  padding-left: 12px;
}

.server-side {
  padding-top: 65px;
}

.content-woman-image {
  width: 100%;
  object-fit: contain;
}

.col {
  flex-grow: unset;
}

@media (max-width: 749px) {
  .circle-container {
    margin-top: unset !important;
  }

  .row {
    margin: 0;
  }
}

@media (max-width: 660px) {
  .technologies-we-use {
    font-size: 25px;
  }

  .technologies-subtitle {
    font-size: 20px;
  }
}

</style>
