<template>
  <div class="our-partners-section" id="our-partners">
    <div class="container">
      <div class="partners-block">
        <p class="our-partners">OUR PARTNERS</p>
        <p class="our-partners-sub p-0 col-12">
          Where Clients Become Partners: It's not just a slogan; it's our commitment. Embracing every project as a
          collaborative venture, we guide you from concept to fruition. With our comprehensive expertise, we not only
          meet your needs but forge enduring partnerships that propel success.
        </p>
      </div>
    </div>
    <marquee class="partners-scroll" behavior="alternate" direction="left" scrollamount="10">
      <img v-for="(slide, index) in slides" :key="index" :src="slide.image" class="d-inline-block mr-2"
           alt="...">
    </marquee>
  </div>
</template>

<script>
export default {
  name: 'OurPartners',
  data() {
    return {
      slides: [
        {image: require("@/assets/img/padelIQ.png")},
        {image: require("@/assets/img/getLaunched.png")},
        {image: require("@/assets/img/rush.png")},
        {image: require("@/assets/img/hartman.png")},
        {image: require("@/assets/img/sportyfriends.png")},
        {image: require("@/assets/img/rsca.png")},
        {image: require("@/assets/img/spaceTogether.png")},
        {image: require("@/assets/img/dorsia.png")},
        {image: require("@/assets/img/intellivy.png")},
        {image: require("@/assets/img/bitband.png")},
        {image: require("@/assets/img/routie.png")},
        {image: require("@/assets/img/kivawork.png")},
        {image: require("@/assets/img/theEvamoreProject.png")},
        {image: require("@/assets/img/golfMore.png")},
        {image: require("@/assets/img/b2connpro.png")},
        {image: require("@/assets/img/trainx.png")},
      ],
      screenWidth: window.innerWidth
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
.our-partners-section {
  background-image: url("../assets/img/professional-person.svg");
  background-repeat: no-repeat;
}

.our-partners {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-top: 85px;
}

.our-partners-sub {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
}

.partners-scroll {
  background-color: #FAFCFF;
  position: relative;
}

.partners-scroll img {
  cursor: pointer;
}

.partners-block {
  padding-top: 50px;
}

@media (max-width: 1540px) {
  .partners-block {
    margin-left: auto;
    width: min(100%, 100vw - 405px);
  }
}

@media (max-width: 810px) {
  .our-partners-section {
    background-image: unset;
  }

  .partners-block {
    margin-left: unset;
    width: unset;
  }
}

@media (max-width: 660px) {
  .our-partners {
    font-size: 25px;
  }

  .our-partners-sub {
    font-size: 20px;
  }
}

</style>
