<template>
  <div>
    <div class="container p-0 " id="about-us">
      <div class="row about-us-section mr-0 ml-0" data-aos="fade-right" data-aos-duration="2000">
        <div class="col-12">
          <p class="about-us">About us</p>
          <p class="about-us-subtitle">
            <span class="highlight">CodeIdea</span> is a fast-growing web and mobile apps development company with great
            experience in software
            developing. Using best practices we offer our clients cloud based rich web applications development
            services.
            We can create your product from the scratch - staring with User Experience and ending with Quality
            Assurance.
            Shipping the high quality product at the right time - this is our formula of success.
          </p>
        </div>
      </div>

      <div class="choose-us">
        <template v-if="screenWidth < 1000">
          <div class="col-lg-6 col-md-12">
            <div class="why-us-boxes-sm-screen">
              <div class="why-us-box">
                <p class="why-us-title">Our values</p>
                <p class="why-us-subtitle">We pride on our ability to create solid products, and execute projects we
                  sign up for with the highest
                  level of professionalism.</p>
              </div>
              <div class="why-us-box mt-3">
                <p class="why-us-title">Being emphatic</p>
                <p class="why-us-subtitle">We don’t just do tech, we care about our clients. We help in every aspect of
                  project. We can see gaps and issues beforehand, that’s our superpower.</p>
              </div>
              <div class="why-us-box mt-3">
                <p class="why-us-title">Deliver on time</p>
                <p class="why-us-subtitle">We love to plan accurately and deliver on time. Our expertise allows us to
                  outline even the slightest complications from the start. </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="choose-us d-flex">
            <div class="col-lg-6 col-md-12">
              <p class="about-us">WHY CHOOSE US?</p>
              <p class="about-us-subtitle">
                We deliver web and mobile development services for our clients with predictable outcomes. For this, we
                hire brilliant web and mobile app developers to deliver projects on time and maintain top-notch code
                standards.
              </p>
            </div>
            <div class="row why-us-boxes" style="position: relative">
              <div class="why-us-box" data-aos="fade-left" data-aos-duration="2000">
                <p class="why-us-title">Our values</p>
                <p class="why-us-subtitle">We pride on our ability to create solid products, and execute projects we
                  sign up for with the highest
                  level of professionalism.</p>
              </div>
              <div class="why-us-box mt-3" data-aos="fade-left" data-aos-duration="2300">
                <p class="why-us-title">Being emphatic</p>
                <p class="why-us-subtitle">We don’t just do tech, we care about our clients. We help in every aspect of
                  project. We can see gaps and issues beforehand, that’s our superpower.</p>
              </div>
              <div class="why-us-box mt-3" data-aos="fade-left" data-aos-duration="2600">
                <p class="why-us-title">Deliver on time</p>
                <p class="why-us-subtitle">We love to plan accurately and deliver on time. Our expertise allows us to
                  outline even the slightest complications from the start. </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutUs',
  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    }
  }
}

</script>

<style scoped>

.about-us-section {
  margin-top: 90px;
}

.about-us {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}

.about-us-subtitle {
  font-size: 24px;
  line-height: 30px;
}

.highlight {
  color: #0479F7;
}

.choose-us {
  margin-top: 300px;
}

.why-us-box {
  box-shadow: 0 -2px 40px 0 rgba(4, 121, 247, 0.13),
  0 -2px 10px 0 rgba(4, 121, 247, 0.33);
  border-radius: 18px;
  padding: 24px 12px;
}

.why-us-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.why-us-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.why-us-boxes {
  margin-top: -150px;
  padding-left: 120px;
}

.why-us-boxes-sm-screen {
  margin-top: unset;
  padding-left: unset;
}

@media (max-width: 1000px) {
  .choose-us {
    margin-top: 100px;
  }

  .why-us-boxes {
    margin-top: 120px;
    padding-left: unset;
  }
}

</style>