<template>
  <div>
    <div :class="{'menu-section' : true, 'fixed-menu' : screenWidth > 900}" id="menu">
      <nav class="navbar navbar-expand-lg navbar-light" v-if="this.screenWidth < 950">
        <a class="navbar-brand" href="#">
          <img src="../assets/img/codeidea.png" class="d-inline-block align-top" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto nav-mb">
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#our-process')">Our Process</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#our-partners')">Our Partners</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#technologies')">Technologies</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#about-us')">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#contact-us')">Contact us</a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="d-flex container justify-content-between flex-wrap menu-part" v-else>
        <div @click="scrollOnTop" style="cursor: pointer">
          <img src="../assets/img/codeidea.png" alt="">
        </div>
        <div id="scrollContainer">
          <ul class="navbar-nav ml-auto nav-full">
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#our-process')">Our Process</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#our-partners')">Our Partners</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#technologies')">Technologies</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="scrollToSection('#about-us')">About us</a>
            </li>
          </ul>
        </div>
        <div class="contact-us-block">
          <button type="button" class="btn contact-us-button-in-menu" @click="scrollToSection('#contact-us')">Contact
            us
          </button>
        </div>
      </div>
    </div>
    <div :class="{'contact-us container' : true, 'vh-100' : screenWidth > 900}">
      <div class="row">
        <div class="col-7 under-navbar-text">
          <div data-aos="fade-down-right"
               data-aos-anchor="#example-anchor"
               data-aos-offset="600"
               data-aos-duration="2000">
            <p class="contact-us-text">
              Delivering Exceptional <span class="highlight">Web</span> and <span class="highlight">Mobile</span>
              Development Services to Drive Your Business Forward
            </p>
            <div class="contact-us-button-block">
              <button type="button" class="btn contact-us-button" @click="scrollToSection('#contact-us')">CONTACT US
              </button>
            </div>
          </div>
        </div>
        <div class="col-5 content-person">
          <div data-aos="fade-up-left"
               data-aos-anchor="#example-anchor"
               data-aos-offset="600"
               data-aos-duration="2000">
            <img src="../assets/img/menu-person.png" alt="" class="content-person-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    },
    scrollToSection(selector) {
      const targetElement = document.querySelector(selector);
      if (targetElement) {
        const offset = targetElement.offsetTop;
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        });
      }
    },
    scrollOnTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
}
</script>

<style scoped>
.nav-link {
  color: #232323;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nav-link:hover {
  color: unset;
}

.navbar-light .navbar-nav .nav-link {
  color: #232323;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: unset;
}

.nav-full {
  display: flex;
  gap: 41px;
  flex-direction: unset;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
}

.nav-mb {
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
}

.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 1);
}

.menu-section {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 8px 9px -5px rgba(20, 20, 20, 0.06),
  0 8px 26px -4px rgba(20, 20, 20, 0.15);
  padding: 32px 160px !important;
  width: 100%;
}

.contact-us {
  margin-top: 150px;
}

.content-person {
  display: flex;
  margin-top: 155px;
}

.under-navbar-text {
  margin-top: 233px;
}

.contact-us-text {
  font-size: clamp(24px, 2vw, 40px);
  font-weight: 700;
  line-height: 49px;
}

.highlight {
  color: #0479F7;
}

.contact-us-button-in-menu {
  border: 3px solid #000000;
  border-radius: 30px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
}

.contact-us-button {
  background-color: rgba(4, 121, 247, 1);
  border-radius: 30px;
  padding: 12px 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 24px;
  font-size: 20px;
  color: white;
}

.navbar-nav.nav-mb {
  animation: fadeInDown 1s ease forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-person-image {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 1300px) {
  .contact-us-block {
    display: none;
  }
}

@media (max-width: 1100px) {
  .menu-part {
    display: block !important;
    text-align: center !important;
  }

  .nav-full {
    justify-content: center;
  }

  .menu-section {
    padding: 32px 100px !important;
  }

  .contact-us {
    margin-top: 0;
  }
}

@media (max-width: 950px) {
  .navbar-nav.nav-mb {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .menu-section {
    padding: 32px 0 !important;
  }

  .nav-full {
    gap: 35px;
  }
}

@media (max-width: 660px) {
  .content-person {
    margin-top: 200px;
  }

  .nav-full {
    gap: 20px;
  }

  .contact-us-text {
    font-size: 16px;
    line-height: unset;
  }
}

@media (max-width: 485px) {
  .nav-full {
    display: block;
  }
}

@media (max-width: 400px) {
  .contact-us-button {
    padding: 5px 10px;
  }
}

</style>
